<app-no-contract *ngIf="this.company && (this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)) ||
['webbooker_license'].includes(this.company.ActiveContract.type))"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"
                 [type]="'debtor'"></app-no-contract>
<mat-card [class]="(this.company && (this.context==='company' &&
(!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)) || this.company.ActiveContract.type
 === 'webbooker_license') ? 'blurText' :'')" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left push-right"
                 placeholder="{{'search_here'|translate}}"></td-search-box>
  <mat-divider></mat-divider>
  <ng-template tdLoading="{{loaderName}}">
    <mat-tab-group (selectedTabChange)="onTabChange($event)"
                   [selectedIndex]="selectedTabIndex" animationDuration="300ms">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">group</mat-icon>
          {{ 'accounts'|translate|ucFirst }}
          <span *ngIf="!this.searchTerm">&nbsp;({{ this.totalRecords['accounts'].length }})</span>
          <span *ngIf="this.searchTerm">&nbsp;({{ this.filteredDebtors['accounts'].length }}
            /{{ this.totalRecords['accounts'].length }})
          </span>
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="sort($event)"
                *ngFor="let c of columns"
                [active]="(sortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                class="{{c.maxSize}}"
                td-data-table-column>
              {{ ((c.name !== 'tools' ? c.label : '')|translate|ucFirst) }}
            </th>
          </tr>
          </thead>
          <tbody *ngIf="filteredDebtors['accounts'].length > 0">
          <tr *ngFor="let item of filteredDebtors['accounts']" td-data-table-row>
            <td (click)="(c.name!='tools' ? open(item.id) : false)" *ngFor="let c of columns"
                class="select {{(c.name=='tools'?'text-right':'')}} {{c.maxSize}}"
                td-data-table-cell>
              <span [ngSwitch]="c.name">
                <span *ngSwitchDefault><span *ngIf="item[c.name]">{{ item[c.name] }}</span><span
                  *ngIf="!item[c.name]">-</span></span>
              </span>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="filteredDebtors['accounts'].length === 0">
          <tr>
            <td [colSpan]="columns.length"
                class="table-placeholder">
              {{ 'no_debtors_in_overview'|translate|ucFirst }}
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #pagingBar (change)="page($event)" [pageSize]="pageSize"
                       [total]="filteredDebtors['accounts'].length">
          <span hide-xs>{{ 'rows_p_page'|translate }}:</span>
          {{ pagingBar.range }} <span hide-xs>of {{ pagingBar.total }}</span>
        </td-paging-bar>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">group</mat-icon>
          {{ 'contacts'|translate|ucFirst }}
          <span *ngIf="!this.searchTerm">&nbsp;({{ this.totalRecords['contacts'].length }})</span>
          <span *ngIf="this.searchTerm">&nbsp;({{ this.filteredDebtors['contacts'].length }}
            /{{ this.totalRecords['contacts'].length }})
          </span>
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="sort($event)"
                *ngFor="let c of contactColumns"
                [active]="(sortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                class="{{c.maxSize}}"
                td-data-table-column>
              {{ ((c.name !== 'tools' ? c.label : '')|translate|ucFirst) }}
            </th>
          </tr>
          </thead>
          <tbody *ngIf="filteredDebtors['contacts'].length > 0">
          <tr *ngFor="let item of filteredDebtors['contacts']" td-data-table-row>
            <td (click)="(c.name!='tools' ?
                openContact(item.id) : false)" *ngFor="let c of contactColumns"
                class="select {{(c.name=='tools'?'text-right':'')}} {{c.maxSize}}" td-data-table-cell>
              <span [ngSwitch]="c.name">
                <span *ngSwitchDefault><span *ngIf="item[c.name]">{{ item[c.name] }}</span><span
                  *ngIf="!item[c.name]">-</span></span>

                <span *ngSwitchCase="'portalAccess'">
                  <span *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span>
                  <span *ngIf="!item[c.name]">{{ 'invited' | translate | ucFirst }}</span>
                </span>
                <span *ngSwitchCase="'tools'">
                  <button [matMenuTriggerFor]="menu" mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu">
                    <button (click)="sendContactEmail(!!(item['lastLogin']), item)" mat-menu-item>
                      <mat-icon>edit</mat-icon>
                      <span>{{(!item['lastLogin'] ? 'resend_invite_email' : 'send_password_reset')|translate|ucFirst}}</span>
                    </button>

                    <button (click)="deleteContact($event, item.id, item.email)" mat-menu-item>
                      <mat-icon>delete</mat-icon>
                      <span>{{'delete'|translate|ucFirst}}</span>
                    </button>
                  </mat-menu>
                </span>
              </span>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="filteredDebtors['contacts'].length === 0">
          <tr>
            <td [colSpan]="contactColumns.length"
                class="table-placeholder">
              {{ 'no_contacts_in_overview'|translate|ucFirst }}
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #pagingBar (change)="page($event)" [pageSize]="pageSize"
                       [total]="filteredDebtors['contacts'].length">
          <span hide-xs>{{ 'rows_p_page'|translate }}:</span>
          {{ pagingBar.range }} <span hide-xs>of {{ pagingBar.total }}</span>
        </td-paging-bar>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>
