import {Component, Input} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PricingRuleService} from 'app/services/tps/pricing-rule.service';
import {Rule, Threshold} from '../../../../../../../models/rule';

type RuleType = 'dynamic' | 'fixed';
type ThresholdType = 'duration' | 'distance';

const capitalize = (s) =>
  String(s).charAt(0).toUpperCase() + String(s).slice(1);

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input() form: UntypedFormGroup;
  @Input() parent: any;
  @Input() prices: any;
  @Input() origin: string;
  @Input() ruleType: 'fixed' | 'dynamic' | 'hourly';

  constructor(
    private _fb: UntypedFormBuilder,
    private _pricingRuleService: PricingRuleService,
  ) {
  }

  /**
   * Adds thresholds to each product of a rule.
   */
  addThreshold(thresholdType: ThresholdType) {
    const ruleType = capitalize(this.prices.root.value.type);
    const minZero = [Validators.required, Validators.min(0)];

    this.prices.controls.map(price => {
      const priceSub = price.controls['price' + ruleType];
      const thresholds = priceSub.controls.thresholds;
      const filter = (threshold: UntypedFormGroup) =>
        threshold.value.type === thresholdType;

      const filteredThreshodls = thresholds.controls.filter(filter);
      const last = filteredThreshodls[filteredThreshodls.length - 1];

      thresholds.push(this._fb.group({
        type: [thresholdType, [Validators.required]],
        threshold: [
          last
            ? last.value.threshold + (this.ruleType === 'dynamic' ? 5 : 1)
            : (this.ruleType === 'dynamic' ? 10 : 1),
          minZero
        ],
        value: [
          (last ? last.value.value :
              (thresholdType === 'distance' ? price.controls.priceDynamic.value.dynamicDistancePrice :
                  (this.ruleType === 'hourly' ? price.controls.priceHourly.value.hourlyPrice : price.controls.priceDynamic.value.dynamicMinutePrice)
              )
          ),
          minZero
        ],
      }));
      thresholds.markAsTouched();
      thresholds.markAsDirty();
    });
  }

  /**
   * Update thresholds for each product of a rule.
   */
  updateThreshold(index: number, event: any) {
    const newValue = event.target.valueAsNumber;
    const ruleType = capitalize(this.prices.root.value.type);

    this.prices.controls.map(price => {
      const priceSub = price.controls['price' + ruleType];
      const thresholds = priceSub.controls.thresholds;
      const v = event.target.valueAsNumber;

      if (!thresholds) {
        return;
      }

      thresholds.controls[index].patchValue({
        threshold: v
      });
      thresholds.markAsTouched();
      thresholds.markAsDirty();
    });
  }

  /**
   * Remove threshold by threshold property.
   */
  removeThreshold(old: Threshold, thresholdType: ThresholdType) {
    this.prices.controls.map(price => {
      let thresholds = price.controls.priceDynamic.controls.thresholds;
      if (this.ruleType === 'hourly') {
        thresholds = price.controls.priceHourly.controls.thresholds;
      }
      const filter = (t: UntypedFormGroup) => t.value.threshold === old.threshold
        && t.value.type === thresholdType

      this.deleteThresholdFrom(thresholds, filter);
      thresholds.markAsTouched();
      thresholds.markAsDirty();
    });
  }

  /**
   * Remove threshold using a filter.
   */
  deleteThresholdFrom = (thresholds: UntypedFormArray, filter: Function) =>
    thresholds.controls.map((t, i) => {
      if (filter(t)) {
        thresholds.removeAt(i)
      }
    });

  /**
   * Get childRule index.
   */
  indexChildRuleById(children: UntypedFormArray, id: string): number {
    return children.controls.findIndex(r => r.value._id === id);
  }

  /**
   * Add new child.
   */
  addChildRule(copyRule?: any, retour?: boolean) {
    const timeframes = [this.form.value.timeframes];

    // Prepare timeframes
    if (timeframes) {
      timeframes.forEach(t => {
        // If weekSchedule was converted to an array, convert it back to string
        if (timeframes.some(t => Array.isArray(t.weekSchedule))) {
          t.weekSchedule = t.weekSchedule.join('')
        }
        // Add random obj id, or the entity can't be
        delete t._id
      });
    }
    let child = new Rule(this.parent.rule.companyId, this.parent.rule._id);
    if (!copyRule) {
      copyRule = JSON.parse(JSON.stringify(this.parent.rule));
      delete copyRule.departureId;
      delete copyRule.departure;
      delete copyRule.destinationId;
      delete copyRule.destination;
    }
    delete copyRule._id;
    copyRule.childRules = [];
    copyRule.companyId = this.parent.rule.companyId;
    child = JSON.parse(JSON.stringify(copyRule));
    if (retour) {
      child.departureId = copyRule.destinationId;
      child.departure = copyRule.destination;
      child.destinationId = copyRule.departureId;
      child.destination = copyRule.departure;
    }
    this.form.markAsDirty();
    this.parent.rule.childRules.push(child);
    const children = <UntypedFormArray>this.form.controls.childRules;
    children.push(this.parent.makeFormFor(child));
  }

  /**
   * Update thresholds for each product of a rule.
   */
  updateChildRulePriceFixed(id: string, priceFixed: UntypedFormGroup, price: number) {
    const toFloat = x => typeof x === 'string' ? parseFloat(x) : x;
    priceFixed.patchValue({
      fixedPrice: toFloat(price)
    })
    console.log(toFloat(price));
    priceFixed.controls.fixedPrice.markAsTouched();
    priceFixed.controls.fixedPrice.markAsDirty();
  }

  /**
   * Remove child.
   */
  removeChildRuleOnSave(id: string) {
    this.parent.removeChildRuleOnSave(id);
  }
}
