import {Component, OnDestroy, OnInit} from '@angular/core';
import {Driver} from '../../../../models/driver';
import {Subscription} from 'rxjs';
import {Debtor} from '../../../../models/debtor';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {IPageChangeEvent} from '@covalent/core/paging';
import {TdLoadingService} from '@covalent/core/loading';
import {DebtorService} from '../../../../services/debtor.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CoolLocalStorage} from '@angular-cool/storage';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../../services/navigation.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../../environments/environment';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {Company} from '../../../../models/company';
import {BusinessLicenseComponent} from '../../../global/business-license/business-license.component';
import {UtilityService} from '../../../../services/utility.service';
import {MatTabChangeEvent} from '@angular/material/tabs';

@Component({
  selector: 'app-debtor-list',
  templateUrl: './debtor-list.component.html',
  styleUrls: ['./debtor-list.component.scss']
})
export class DebtorListComponent extends BusinessLicenseComponent implements OnInit, OnDestroy {
  context: string;
  driver: Driver;
  company: Company;
  companyId: string;
  loaderName = 'debtor-list';
  dataSubscription: Subscription;

  debtors: Debtor[] = [];
  filteredDebtors: Debtor[] = [];
  filteredDebtorCount = 0;
  selectedTabIndex: any = 0;
  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  pageSize = 20;
  sortBy = 'identifier';
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;
  activeTab = 'all';

  accountsCount: 0;
  contactsCount: 0;
  allFilteredData: [];

  columns = [
    {name: 'code', label: 'code', sortable: true, active: false},
    {name: 'firstName', label: 'first_name', sortable: true, active: true},
    {name: 'lastName', label: 'last_name', sortable: true, active: false},
    {name: 'companyName', label: 'debtor_company', sortable: true, active: false},
    {name: 'emailAddress', label: 'email', sortable: true, active: false},
  {name: 'phoneNumber', label: 'phone_number', sortable: true, active: false}
  ];

  constructor(private debtorService: DebtorService,
              private router: Router,
              private _vault: CoolLocalStorage,
              private _route: ActivatedRoute,
              private dataTableService: TdDataTableService,
              private loadingService: TdLoadingService,
              private translateService: TranslateService,
              private navigationService: NavigationService,
              private _titleService: Title) {
    super();
    this.context = this._route.routeConfig['context'] || 'driver';
    this.companyId = this._route.parent.snapshot.paramMap.get('id');

    if (this.context === 'company') {
      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
      this.setBusinessContract(_vault);
    } else {
      this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
    }
    translateService.get(['menu_mydebtors', 'group_customers']).subscribe((translations: any) => {
      if (this.context === 'driver') {
        this._titleService.setTitle(translations['menu_mydebtors'] + environment.windowTitleSuffix);
        this.navigationService.setBodyTitle(ucFirst(translations['menu_mydebtors']));
        this.navigationService.setActionLink(`/personal/debtors/add`);
      } else {
        this._titleService.setTitle(translations['group_customers'] + environment.windowTitleSuffix);
        this.navigationService.setBodyTitle(ucFirst(translations['group_customers']));
        this.navigationService.setActionLink(`/groups/${this.companyId}/debtors/add`);
      }
    });
  }

  ngOnInit(): void {
    this.loadingService.register(this.loaderName);
    this.loadData();
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
    this.navigationService.setActionLink('');
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.filter();
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.activeTab = 'all';
    } else if (event.index === 1) {
      this.activeTab = 'map';
    } else if (event.index === 2) {
      this.activeTab = 'companyWebUsers';
    } else if (event.index === 3) {
      this.activeTab = 'blocked';
    }
    this._vault.setObject(`${environment.vaultPrefix}.contact.tab`, event.index);
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.filter();
  }

  page(pagingEvent: IPageChangeEvent): void {
    this.fromRow = pagingEvent.fromRow;
    this.currentPage = pagingEvent.page;
    this.pageSize = pagingEvent.pageSize;
    this.filter();
  }

  filter(): void {
    let filteredData: Debtor[] = this.debtors;

    filteredData = this.dataTableService.filterData(filteredData, this.searchTerm, true);
    filteredData = this.dataTableService.sortData(filteredData, this.sortBy, this.sortOrder);
    filteredData = this.dataTableService.pageData(filteredData, this.fromRow, this.currentPage * this.pageSize);

    this.filteredDebtorCount = filteredData.length;
    this.filteredDebtors = filteredData;
  }

  open(id: string): void {
    if (id) {
      if (this.context === 'driver') {
        this.router.navigate([`/debtors/${id}/view/`]);
      } else {
        this.router.navigate([`/groups/${this.companyId}/debtors/${id}/view/`]);
      }
    }
  }

  loadData(): void {
    const query = {
      where: {
        ownerId: (this.context === 'driver' ? this.driver.id : this.companyId),
        ownerType: ucFirst(this.context)
      }
    };
    this.dataSubscription = this.debtorService.getAll(query, this.context).subscribe((debtors: Debtor[]) => {
      this.debtors = debtors;
      this.filteredDebtorCount = debtors.length;

      this.filter();

      const self = this;
      setTimeout(function () {
        self.loadingService.resolve(self.loaderName);
      }, 500);
    }, error => {
      console.error(error);
      this.loadingService.resolve(this.loaderName);
    })
  }

}
