<mat-list-item>
  <mat-icon mat-list-icon>access_time</mat-icon>
  <h4 mat-line>{{ 'time'|translate|ucFirst }}</h4>
</mat-list-item>

<div [formGroup]="form" [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom date-picker-pane" class="mobile-row"
     layout-gt-sm="row" tdMediaToggle="gt-xs">

  <mat-list-item class="value" flex-gt-sm="50" flex="50">
    <mat-form-field flex="100">
      <input matInput [ngxMatDatetimePicker]="picker"
             (dateChange)="setRequestedDate($event)"
             (keyup)="setRequestedDate($event)"
             placeholder="{{ 'pickuptime' | translate | ucFirst }}"
             [min]="minDate"
             [max]="maxDate"
             [disabled]="disabled"
             formControlName="requestedDate">
      <mat-datepicker-toggle matSuffix [for]="picker" #datepickerToggle></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #picker
                               [showSpinners]="showSpinners"
                               [showSeconds]="false"
                               [stepHour]="stepHour"
                               [stepMinute]="stepMinute"
                               [stepSecond]="stepSecond"
                               [touchUi]="touchUi"
                               [color]="color"
                               [enableMeridian]="enableMeridian">
      </ngx-mat-datetime-picker>
      <mat-error *ngIf="form.controls['requestedDate'].errors && form.controls['requestedDate'].errors.matDatetimePickerMin">{{'date_error_too_early' | translate | ucFirst}}</mat-error>
    </mat-form-field>
  </mat-list-item>
  <mat-list-item class="mobile-row" flex="50" flex-gt-sm="50">
    <div class="value" flex="100" flex-gt-sm="50">
      <mat-checkbox class="checkbox" flex-gt-sm="50" formControlName="isHourlyBooking" name="isHourlyBooking">
        {{ 'hourly_booking' | translate | ucFirst }}
      </mat-checkbox>
    </div>

    <mat-form-field *ngIf="this.form.controls['isHourlyBooking'].value" class="value" flex="100" flex-gt-sm="50"
                    formGroupName="priceMeta">
      <mat-label>{{ 'booked_duration'|translate|ucFirst }}</mat-label>
      <!-- The timepicker input -->
      <input (change)="bookedDurationChange()"
             defaultValue="00:00"
             formControlName="bookedDuration"
             id="timepicker-example"
             matInput
             mode="24h"
             name="time"
             strict="false"
             type="time">
    </mat-form-field>
  </mat-list-item>
</div>
