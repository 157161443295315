import {AfterViewInit, Component, Input, OnChanges, OnInit} from '@angular/core';
import {Address} from '../../../../../models/address';
import {UtilityService} from '../../../../../services/utility.service';

@Component({
  selector: 'app-ride-location-data',
  templateUrl: './ride-location-data.component.html',
  styleUrls: ['./ride-location-data.component.scss']
})
export class RideLocationDataComponent implements OnInit, OnChanges {

  @Input() stopOvers: Address[];
  @Input() departure: Address;
  @Input() actualDeparture: Address;
  @Input() destination: Address;
  @Input() actualDestination: Address;
  @Input() metrics: any = {};

  departureString: string;
  actualDepartureString: string;
  destinationString: string;
  actualDestinationString: string;
  expanded = false;
  googleMapsLink = '';

  protected readonly UtilityService = UtilityService;

  constructor() {

  }

  ngOnInit() {
    if (this.departure.gps.lat) {
      let stopOverGpsString = '';
      if (this.stopOvers && this.stopOvers.length > 0) {
        for (let i = 0; i < this.stopOvers.length; i++) {
          stopOverGpsString += this.stopOvers[i].gps.lat + ',' + this.stopOvers[i].gps.lng + (i < this.stopOvers.length - 1 ? '/' : '');
        }
      }
      this.googleMapsLink = 'https://www.google.com/maps/dir/' + this.departure.gps.lat + ',' + this.departure.gps.lng + '/' + (stopOverGpsString ? stopOverGpsString : '') + '/' + this.destination.gps.lat + ',' + this.destination.gps.lng;
    } else {
      setTimeout(() => this.ngOnInit(), 1000);
    }
  }

  ngOnChanges() {
    this.departureString = UtilityService.getLocationString(this.departure);
    this.actualDepartureString = UtilityService.getLocationString(this.actualDeparture);
    this.destinationString = UtilityService.getLocationString(this.destination);
    this.actualDestinationString = UtilityService.getLocationString(this.actualDestination);
  }
}
